<template>
  <div>
    <default-app-bar :showCancel="false" :enabledMenu="false">
      <template v-slot:menu>
        <v-app-bar-nav-icon
          @click.stop="toogleDrawer"
          color="primary"
        ></v-app-bar-nav-icon>
      </template>
      <template v-slot:title>
        <v-toolbar-title class="px-0">
          <img
            src="/assets/images/brand/primary-horizontal-logo.svg"
            class=""
            width="180"
          />
        </v-toolbar-title>
        <span class="d-none d-md-block ml-1 primary--text text-button" v-if="localVersion"
          >V{{ localVersion }}</span
        >
      </template>

      <template v-slot:actions>
        <v-row>
          <v-col cols="12" class="pl-2 d-flex flex-row align-center">
            <div
              class="d-flex flex-row align-center me-2"
              v-if="!$store.getters.mobile"
            >
              <v-btn text small class="white--text me-2">{{
                $t("price_list")
              }}</v-btn>
              <ng-select-options
                class="price_list"
                v-if="$pos && $pos.priceLists"
                v-model="currentPriceList"
                :items="$pos.priceLists"
                @change="changePriceList"
                :clearable="false"
                :flat="false"
                :filled="false"
                dense
                :outlined="true"
                hide-details
                :full-width="false"
              ></ng-select-options>
            </div>

            

            <div class="d-flex flex-row grey lighten-4 rounded-pill py-1 px-2 me-2">
              <v-btn
                fab
                :x-small="$store.getters.mobile ? true : false"
                :small="!$store.getters.mobile ? true : false"
                :title="$t('pending_orders')"
                @click="openModalCartPendingOrders()"
              >
                <v-badge
                  color="orange darken-2"
                  :content="'' + totalPendingOrders"
                  overlap
                  :key="$store.getters.orderChanged"
                >
                  <v-icon color="orange darken-2">mdi-receipt</v-icon>
                </v-badge>
              </v-btn>

              <v-btn
                fab
                :x-small="$store.getters.mobile ? true : false"
                :small="!$store.getters.mobile ? true : false"
                @click="updateInfo()"
                :title="$t('syncro')"
                ><v-icon color="blue">mdi-cloud-sync-outline</v-icon>
              </v-btn>

              <v-btn icon small :color="Math.ceil($pos.getCurrentOrder().getTotalItems()) > 0 ? 'green darken-2' : ''">
  <v-badge
  color="green darken-2"
                      :x-small="$store.getters.mobile ? true : false"
                      :small="!$store.getters.mobile ? true : false"
                      v-if="Math.ceil($pos.getCurrentOrder().getTotalItems()) > 0"
                      :content="
                        Math.ceil($pos.getCurrentOrder().getTotalItems())
                      "
                      overlap
                      :key="$store.getters.orderChanged"
  >
    <v-icon>mdi-basket</v-icon>
  </v-badge>
  <v-icon v-else>mdi-basket</v-icon>
</v-btn>
                    
            </div>

            <!---User -->
            <div class="balance-contrast pr-2 rounded-pill">
            <v-menu
              bottom
              left
              offset-y
              origin="top right"
              transition="scale-transition"
              class="account-menu"
            >
              <template v-slot:activator="{ on }">
                <v-btn 
                v-on="on"
                    dark
                    icon
                    color="transparent"
                    elevation="0"
                    id="btn-menu-profile">
                  <v-avatar size="35" class="primary">
                    <v-icon color="white" size="18">mdi-account</v-icon>
                  </v-avatar>
                  <v-icon color="grey">mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list style="width: 350px !important">
                  <v-list-item
                    v-if="$store.getters.user"
                    class="d-flex justify-center align-center"
                  >
                    <div class="account-header text-center">
                      <v-list-item two-line class="pa-0 red mx-auto">
                        <div>
                          <v-list two-line>
                            <v-list-item>
                              <v-list-item-avatar
                                v-if="
                                  $store.getters.company &&
                                  $store.getters.company.logo
                                "
                              >
                                <img
                                  :src="
                                    $http.getBaseUrlPublicFiles() +
                                    $store.getters.company.logo
                                  "
                                  :alt="$store.getters.company.name"
                                />
                              </v-list-item-avatar>

                              <v-list-item-content class="text-left">
                                <v-list-item-title
                                  v-if="$store.getters.company"
                                  class="pb-0 primary--text"
                                  >{{ $store.getters.company.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle
                                  v-if="$store.getters.user"
                                  >{{
                                    $store.getters.user.name
                                  }}</v-list-item-subtitle
                                >

                                <div class="d-flex align-center">
                                  <span
                                    v-if="
                                      $store.getters.user.rol &&
                                      $store.getters.user.rol.name
                                    "
                                    class="me-1"
                                    ><v-chip small>{{
                                      $store.getters.user.rol.name
                                    }}</v-chip></span
                                  >
                                  <span
                                    v-if="app_version"
                                    class="caption grey--text"
                                    >v{{ app_version }}</span
                                  >
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </div>
                      </v-list-item>
                    </div>
                  </v-list-item>

                  <v-divider></v-divider>

                  <v-list-item @click="openModalCartPendingOrders()">
                    <v-list-item-icon
                      class="orange lighten-5 py-2 px-2 rounded"
                    >
                      <v-badge
                        color="orange darken-2"
                        :content="'' + totalPendingOrders"
                        overlap
                      >
                        <v-icon color="orange darken-1">mdi-receipt</v-icon>
                      </v-badge>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="orange--text">
                        {{ $t("pending_orders") }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("pending_desc") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item @click="updateInfo()">
                    <v-list-item-icon class="primary py-2 px-2 rounded">
                      <v-icon color="white">mdi-cloud-sync-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="primary--text">
                        {{ $t("syncro") }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t("syncro_desc") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    id="menu-item-logout"
                    class="text-center grey--text"
                  >
                    <v-btn
                      @click="$store.dispatch('logoutAuth')"
                      elevation="0"
                      block
                      outlined
                      color="primary"
                      class="rounded-lg"
                      >{{ $t("logout") }}</v-btn
                    >
                  </v-list-item>
                </v-list>
            </v-menu>
          </div>
            <!---User -->
          </v-col>
        </v-row>
        <!--a v-if="$store.getters.mobile" @click="dialogFilter = true"><v-icon>mdi-magnify</v-icon></a-->
      </template>
    </default-app-bar>

    <!-- m="4" md="4" lg="4" -->
    <v-row v-if="installed" tabindex="0" class="ma-0 pa-0"
    :class="
        $store.getters.mobile ? 'cart_mobile mobile-height' : 'desktop-height'
      ">
      <template v-if="$store.getters.mobile">
        <v-col cols="12" v-if="!showCartMobile" class="pa-0 mb-0">
          <v-toolbar dark color="info" dense class="fixed-bar" height="26">
            <v-toolbar-title class=""
              >{{ $t("total") }}
              {{
                $filters.currency(
                  $pos.getCurrentOrder().getPriceTotalWithAdds(),
                  0
                )
              }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-toolbar-title>{{ $t("view_order") }}</v-toolbar-title>
            <v-btn
              v-if="$store.getters.mobile"
              :x-small="$store.getters.mobile ? true : false"
              fab
              color="grey lighten-5"
              @click="viewCartMobile(true)"
            >
              <v-icon color="primary">mdi-arrow-right</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>

        <v-col cols="12" v-if="showCartMobile" class="pa-0">
          <v-toolbar dark color="blue" dense class="fixed-bar">
            <v-btn
              fab
              small
              color="white"
              @click="viewCartMobile(false)"
              class="me-2"
            >
              <v-icon color="primary">mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t("go_back_products") }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-col>
      </template>

      <SearchProducts
        @select:product="manageProductSelected"
        @select:product-variant="manageProductVariantSelected"
        @select:product-batch="manageProductSelected"
        v-if="!showCartMobile"
        :keyReload="keyReloadProducts"
        :is_mobile="$store.getters.mobile"
        :aspect_ratio="$store.getters.aspect_ratio"
        :priceList="currentPriceList"
        ref="searchProd"
      ></SearchProducts>

      <CartSection
        class="cart_section"
        :order="currentOrder"
        @edit:item="editItemLine"
        :key="cartKey"
        @created:order="viewCartMobile(false)"
        :is_mobile="$store.getters.mobile"
        :aspect_ratio="$store.getters.aspect_ratio"
        v-if="calculateShowCartMobile"
      ></CartSection>
    </v-row>

    <InstallWaiter
      :open="openInstall"
      @cancel="cancelEditProduct()"
      @installed="installedOk"
    ></InstallWaiter>

    <CartSectionProductDetail
      :dialog="modalItem"
      :key="key"
      @selectedOptions="selectedOptions"
      :currentProduct="currentProduct"
      :itemLine="currentItemLine"
      @cancel="closeItemDetail"
      :priceList="currentPriceList"
    >
    </CartSectionProductDetail>

    <CartPendingOrdersRemote
      :dialog="modalCartPendingOrders"
      @cancel="modalCartPendingOrders = false"
      ref="modalPendingOrders"
      @close="modalCartPendingOrders = false"
    ></CartPendingOrdersRemote>

    <v-dialog v-model="showWarningOrderWithoutSave" width="350">
      <v-card>
        <v-card-text
          class="pt-4 text-center"
        >
          Actualmente hay una orden en proceso. Por favor, finaliza o cancela la
          orden antes de proceder con una nueva.
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            @click="showWarningOrderWithoutSave = false"
            color="primary"
            outlined
            block
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
.v-main__wrap {
  padding-top: 21px !important;
}

body,
html {
  overflow: hidden;
}

@media only screen and (max-width: 981px) {
  body,
  html {
    overflow: visible !important;
  }
}

.desktop-height {
  height: calc(100vh - 65px) !important;
}

.mobile-height {
  height: 100vh !important;
}

.price_list .v-text-field fieldset,
.price_list .v-text-field .v-input__control,
.price_list .v-text-field--single-line .v-select__selections,
.price_list .v-select__selection,
.price_list .v-icon {
  color: white !important;
}

.fixed-bar {
  position: fixed;
  top: 70px;
  min-height: 70px;
  width: 100%;
  z-index: 2;
  box-shadow: none !important;
}

#search_prd.cart_mobile{
  margin-top: 70px !important;
}


.cart_section.cart_mobile {
  margin-top: 70px !important;
}


.cart_section.cart_mobile.tip {
  margin-top: 30px !important;
}

.cart_section.cart_mobile.coupon {
  margin-top: 30px !important;
}

.account-menu .v-list .v-list-item--active {
  color: transparent !important;
}
.balance-contrast {
  background: rgba(0, 0, 0, 0.01);
  box-shadow: rgba(0, 0, 0, 0.01) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
}
</style>
  
<script>
import SearchProducts from "./../pos/components/SearchProducts";
import InstallWaiter from "./InstallWaiter";
import OpenTurn from "./../pos/components/OpenTurn";
import CartSection from "./CartSectionWaiters";
import CartSectionProductDetail from "./../pos/components/CartSectionProductDetail";
import CartPendingOrdersRemote from "./../pos/components/CartPendingOrdersRemote";

export default {
  name: "WaitersIndex",
  components: {
    SearchProducts,
    CartSection,
    CartSectionProductDetail,
    CartPendingOrdersRemote,
    InstallWaiter,
    OpenTurn,
  },
  data: () => ({
    openInstall: false,
    ws: null, //websocket local, funciona sin internet, para validar que nose conecten varias ventanas del pos
    socket: null, //websocket nube, para validar si esta abierta la caja y otras validaciones
    openTurn: false,
    installed: false,
    key: 0,
    cartKey: 0,
    products: [],
    currentPriceList: null,
    priceLists: [],
    currentOrder: null,
    modalItem: false,
    modalCartPendingOrders: false,
    currentProduct: false,
    currentItemLine: false,
    keyReloadProducts: 0,
    databaseLoaded: false,
    totalPendingOrders: 0,
    app_version: null,
    turn: null,
    showWarningOrderWithoutSave: false,
    showCartMobile: false,
    localVersion: '',
    //cart: null
    //priceList:
  }),
  computed: {
    currentTurn() {
      return this.turn;
    },
    calculateShowCartMobile(){
      if(this.$store.getters.mobile){
        return this.showCartMobile;
      }else{
        return true;
      }
    },
  },
  watch: {
    "$store.getters.orderChanged": function () {
      this.loadGeneralInfo();
    },
    "$store.getters.online": function () {
      if (this.$store.getters.online) {
        this.$pos.sincronizeSync();
      }
    },
    "$store.state.db.loaded": async function  () {
      this.databaseLoaded = true;
      console.log("loaded bd.............");
      await this.installedEvent();

      /* setTimeout(() => {
          this.installedEvent();
        }, 1000);*/
    },
  },
  mounted() {
    setTimeout(async () => {
      this.installedEvent();
    }, 500);
    this.localVersion =  localStorage.getItem("app_version");
  },
  methods: {
    windowClose() {
      window.close();
    },

    changePriceList() {},
    async testPrint() {
      const template = await this.$db.getTemplate(2);
      this.$store.dispatch("printData", {
        tmpl: template.body,
        info: { name: "" },
      });
    },
    openHelp() {
      window.open(process.env.VUE_APP_BASECONFIG_URL_HELP, "_blank");
    },
    async loadGeneralInfo() {
      const orders_pending = await this.$db.getOrdersByType("pending");
      this.totalPendingOrders = orders_pending.length;
    },
    closeItemDetail() {
      this.modalItem = false;
      this.key++;
    },
    anullCurrentOrderClick() {
      this.showWarningOrderWithoutSave = false;
      //this.anullCurrentOrder = true;
    },
    newOrder() {
      if (this.$pos.getCurrentOrder().isEmpty()) {
        let defaults = { is_on_table: true, from_seller: true };
        this.$pos.createOrder(false, defaults);
      } else {
        //this.anullCurrentOrder = true;
        this.showWarningOrderWithoutSave = true;
      }
    },
    modalOpenTurn() {
      this.openTurn = true;
    },
    launchMenuPos(evt) {
      return false;
    },

    installedOk() {
      setTimeout(() => {
        //
        this.installedEvent();
        // window.location.href = "/pos";
      }, 1);
      //
    },
    async installedEvent() {
      if (
        this.$store.state &&
        this.$store.state.db &&
        this.$store.state.db.loaded
      ) {
        const installed = await this.$db.itsInstalled();
        if (installed) {
          let installation = this.$store.getters.installation;
          if (!installation) {
            installation = await this.$db.adapter.getInstallation();
            this.$store.dispatch("setInstallation", installation);
          }
          this.$pos.priceLists = await this.$db.getPriceLists();
          this.currentPriceList = this.getCurrentList();
          this.installed = true;
          this.openInstall = false;
          this.loadGeneralInfo();
          this.openCloudSocket();
          this.key++;
          this.keyReloadProducts++;
        } else {
          this.openInstall = true;
        }
      }
      //this.load();
    },
    openCloudSocket() {},
    getCurrentList() {
      for (const ls of this.$pos.priceLists) {
        if (ls.is_general == true) {
          return ls;
        }
      }
      return this.$pos.priceLists[0];
    },
    cancelEditProduct() {
      this.currentProduct = null;
      this.modalItem = false;
    },
    //6545353890355
    async manageProductVariantSelected(variant) {
      let product = await this.$db.getProduct(variant.inv_product_id);
      this.$pos.addItem(
        product,
        {
          id: variant.id,
          name: variant.name,
          reference: variant.reference,
          bar_code: variant.bar_code,
        },
        1,
        "",
        null,
        null,
        -1
      );
      this.$store.commit("setOrderChanged", true);
    },
    focusFieldQuantityScale() {
      setTimeout(() => {
        const element = document.getElementById("quantity-field");
        if (element !== null) {
          element.focus();
          element.select();
        }
      }, 325);
    },
    // adiciona los productos seleccionados, toma siempre la current list
    manageProductSelected(product) {
      this.currentProduct = null;
      this.currentItemLine = null;
      if (product.type == "variant") {
        this.key++;
        this.currentProduct = product;
        this.modalItem = true;
      } else {
        if (product.sale_options || product.is_scale == true) {
          this.key++;
          this.currentProduct = product;
          this.modalItem = true;
          this.focusFieldQuantityScale();
        } else {
          this.$pos.addItem(
            product,
            null,
            null,
            null,
            null,
            null,
            -1,
            this.currentPriceList.id
          );
        }
      }
    },
    editItemLine(itemLine) {
      this.currentProduct = null;
      this.currentItemLine = itemLine;
      this.modalItem = true;
      this.key++;
      if (itemLine.product && itemLine.product.is_scale) {
        this.focusFieldQuantityScale();
      }
    },
    async selectedOptions(options) {
      if (options.options) {
        for (let i in options.options) {
          if (
            options.options[i] &&
            options.options[i].value &&
            Array.isArray(options.options[i].value) &&
            options.options[i].value.length == 0
          ) {
            delete options.options[i];
          }
        }
      }
      if (options.itemLine) {
        const product = await this.$db.getProduct(options.itemLine.product.id);
        await this.$pos.updateItem(
          options.itemLine,
          product,
          options.variant,
          options.quantity,
          options.note,
          options.options,
          options.discount,
          options.new_price,
          options.bill_price_list_id
        );
        this.cartKey++;
      } else {
        this.$pos.addItem(
          this.currentProduct,
          options.variant,
          options.quantity,
          options.note,
          options.options,
          options.discount,
          options.new_price,
          options.bill_price_list_id
        );
      }
      this.modalItem = false;
    },
    toogleDrawer() {
      this.$store.commit("toggle");
    },
    openModalCartPendingOrders() {
      this.modalCartPendingOrders = true;
      this.$refs.modalPendingOrders.getPendingOrders();
    },
    async updateInfo() {
      await this.$pos.sincronizeSync();
      const syncroRemote = await this.$pos.syncroRemoteToLocal();
      this.$refs.searchProd.updateProducts({}, 20, "name");

      this.keyReloadProducts++;

      if (syncroRemote) {
        setTimeout(() => {
          this.$store.commit("setLoading", false);
          window.location.reload();
        }, 5000);
      }
    },
    viewCart(view) {
      if (this.currentTurn) {
        if (view == "search_products") {
          if (this.$store.getters.mobile) {
            if (!this.showCartMobile) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
        if (view == "cart") {
          if (this.$store.getters.mobile) {
            if (this.showCartMobile) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      } else {
        return false;
      }
    },
    viewCartMobile(value) {
      this.showCartMobile = value;
    },
  },
};
</script>
  